.navigation-separator {
  color: black;
  padding-left: 8px;
  padding-right: 8px;
}

.navigation-link {
  color: black !important;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}
