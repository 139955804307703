.key-value-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;

  &:hover {
    background-color: lightgray;
  }

  .key {
    width: 116px;
  }

  .values {
    font-weight: bolder;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    flex-direction: column;
  }

  .value-color-container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    gap: 8px;
  }

  .value-image-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    gap: 4px;

    .value-image-link {
      width: 80px;
    }

    .value-image {
      max-width: 95%;
      max-height: 5em;
      width: auto !important;
    }
  }
}
