.card {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
  border: 1px solid;
  border-radius: 8px;
  align-self: stretch;
  padding: 16px;
  gap: 16px;
}
